<template>
    <div class="page">
        <div class="content">
            <div class="top">
                <Button @click="goExport">导出</Button>
                <Upload 
                    ref="upload"
                    :show-upload-list="false"
                    :format="['xls']"
                    :max-size="2048"
                    :on-format-error="handleFormatError"
                    :on-success="wagesImportSuccess"
                    :on-progress="handleProgress"
                    :action="uploadImg"
                    :headers="headers"
                    >
                    <Button type="primary" style="background: #296FE1;margin-left: 30px;">导入</Button>
                </Upload>
            </div>
            <div style="min-height: 70vh;">
                <Table :columns="columnsTable" :data="dataTable" border>
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="jobs">
                        <div>{{row.jobs.name}}</div>
                    </template>
                    <template slot-scope="{ row }" slot="manage">
                        <div style="display: flex;flex-direction: row;align-content: center;justify-content: space-around;">
                            <Button type="error" ghost v-if="row.status == 0" @click="prompt(1,row)" label="small">审核</Button>
                            <Button type="primary" ghost v-if="row.status == 1 || row.status == 2" @click="prompt(2,row)" label="small">查看</Button>
                            <Upload 
                                ref="upload"
                                :show-upload-list="false"
                                :format="['xls']"
                                :max-size="2048"
                                :on-format-error="handleFormatError"
                                :on-success="wagesImportSuccess"
                                :on-progress="handleProgress"
                                :action="uploadImg"
                                :headers="headers"
                                :data='uploadData'
                                >
                                <Button type="primary" ghost label="small" @click="reImport(row)">重新导入</Button>
                            </Upload>
                            <Button type="error" ghost v-if="row.status == 0 || row.status == 1" @click="prompt(3,row)" label="small">删除</Button>
                        </div>
                    </template>
                </Table>
            </div>
            <Page class="Page" :current="storePrams.page" :page-size="storePrams.limit" @on-change="pageChange" :total="total" show-elevator show-total />
        </div>
        <!-- 导出 -->
        <Modal v-model="exportModal" title="导出">
            <div>
                <div class="top" style="margin: 0;justify-content: space-around;margin-bottom: 20px;">
                    <DatePicker type="daterange" @on-change="changeDate" placement="bottom-start" placeholder=" 开始时间 至 结束时间 " style="width:15vw" separator=" 至 "></DatePicker>
                    <Select v-model="storePramsExport.settle" style="width: 10vw;" placeholder="请选择结算方式" @on-change="settleChange">
                        <Option v-for="item in settleList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </div>
            </div>
            <div slot="footer">
                <Button type="primary" style="background: #296FE1;" @click="wagesExport">导出</Button>
            </div>
        </Modal>
        <!-- 操作 -->
        <Modal v-model="promptModal" :title="title" :width="promptValue == 3 ? 20 : 60">
            <div>
                <div v-if="promptValue == 1 || promptValue == 2">
                    <Table :columns="columnsTableModal" :data="dataTableModal" border>
                        <template slot-scope="{ index }" slot="id">
                            <div>{{ index + 1 }}</div>
                        </template>
                        <template slot-scope="{ row }" slot="jobs_title">
                            <div v-if="row.signup.jobs">{{row.signup.jobs.title}}</div>
                            <div v-else>{{row.jobs_title}}</div>
                        </template>
                        <template slot-scope="{ row }" slot="name">
                            <div v-if="row.signup.user">{{row.signup.user.name}}</div>
                            <div v-else>{{row.name}}</div>
                        </template>
                        <template slot-scope="{ row }" slot="status_text">
                            <div :style="row.status == 3 ? 'color: #f00;' : ''">{{row.status_text}}</div>
                        </template>
                    </Table>
                    <Page class="Page" :current="storePramsModal.page" :page-size="storePramsModal.limit" @on-change="pageChangeModal" :total="totalModal" show-elevator show-total />
                </div>
                <div v-if="promptValue == 3" class="content-modal" style="min-height: 100px;">
                    <div class="p-modal">是否确定删除!</div>
                </div>
            </div>
            <div slot="footer">
                <div v-if="promptValue == 1 || promptValue == 3">
                    <Button @click="cancelModal">取消</Button>
                    <Button v-if="promptValue == 1" type="error" ghost @click="fixModal">确认审核</Button>
                    <Button v-else type="primary" style="background: #296FE1;" @click="fixModal">确定</Button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { wagesExport, wages_import_list, wages_import_infos, wages_del_export_file, wages_examine_file } from "@/api/index";
import { getCookies } from "@/utils/helper";
import config from "@/config/index";
    export default {
        data() {
            return {
                uploadData: {id: ''},
                uploadImg: config.apiUrl + "/pc/wages/import",
                headers: {"authori-zation-jobs-pc": getCookies("authori-zation-jobs-pc")},
                total: 0,
                totalModal: 0,
                jobs_id: '',
                storePrams: {
                    page: 1,
                    limit: 30
                },
                columnsTable: [
                    {title: '#',slot: "id",align:'center',width:'130px'},
                    {title: '文件名称',key: 'name',align:'center'},
                    {title: '时间',key: 'created_at',align:'center'},
                    {title: '驳回次数',key: 'info_count',align:'center'},
                    {title: '操作',slot: 'manage',align:'center'}
                ],
                dataTable: [],
                exportModal: false,
                settleList: [
                    { id: '1', name: '日结' },
                    { id: '2', name: '月结' },
                    { id: '3', name: '周结' },
                    { id: '4', name: '完工结' },
                    { id: '5', name: '面议' }
                ],
                promptModal: false,
                title: '',
                columnsTableModal: [
                    {title: '#',slot: "id",align:'center',width:'80px'},
                    {title: '标题',slot: 'jobs_title',align:'center'},
                    {title: '名称',slot: 'name',align:'center'},
                    {title: '工资',key: 'wages',align:'center'},
                    {title: '推广费',key: 'refund_fee',align:'center'},
                    {title: '利润',key: 'profit',align:'center'},
                    {title: '客服费',key: 'customer_service_free',align:'center'},
                    {title: '状态',slot: 'status_text',align:'center'}
                ],
                dataTableModal: [],
                storePramsModal: {
                    page: 1,
                    limit: 30,
                    id: ''
                },
                storePramsExport: {
                    start_date: '',
                    end_date: '',
                    settle: ''
                },
                promptValue: ''
            }
        },
        mounted() {
            this.wages_import_list()
        },
        methods: {
            reImport(e){
                this.uploadData.id = e.id
            },
            settleChange(e){
                this.storePramsExport.settle = e
            },
            wages_import_list(){
                wages_import_list().then(res => {
                    this.dataTable = res.data.data
                    this.total = res.data.total
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            pageChangeModal(index){
                this.storePramsModal.page = index;
            },
            prompt(i,e){
                this.promptValue = i
                this.promptModal = true
                this.storePramsModal.id = e.id
                if(i == 1){
                    this.title = '审核'
                    this.wages_import_infos()
                }else if (i == 2){
                    this.title = '查看'
                    this.wages_import_infos()
                }else{
                    this.title = '提示'
                }
            },
            wages_import_infos(){
                wages_import_infos(this.storePramsModal).then(res => {
                    this.totalModal = res.data.total
                    this.dataTableModal = res.data.data
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.wages_import_list()
            },
            //导出
            goExport(){
                this.exportModal = true
            },
            wagesExport(){
                wagesExport(this.storePramsExport).then(res=>{
                    window.open(res.data)
                    this.exportModal = false
                })
                .catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            changeDate(e){
                if(e[0]){
                    this.storePramsExport.start_date = e[0]
                    this.storePramsExport.end_date = e[1]
                }else {
                    this.storePramsExport.start_date = ''
                    this.storePramsExport.end_date = ''
                }
            },
            cancelModal(){
                this.promptModal = false
            },
            fixModal(){
                this.promptModal = false
                if(this.promptValue == 1){
                    wages_examine_file({id: this.storePramsModal.id}).then(res => {
                        this.wages_import_list()
                        this.$Message.success(res.msg);
                    }).catch((res) => {
                        this.$Message.error(res.msg);
                    });
                }
                if(this.promptValue == 3){
                    wages_del_export_file({id: this.storePramsModal.id}).then(res => {
                        this.wages_import_list()
                        this.$Message.success(res.msg);
                    }).catch((res) => {
                        this.$Message.error(res.msg);
                    });
                }
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            // 文件上传时
            handleProgress() {
                const msg = this.$Message.loading({
                    content: "上传中...",
                    duration: 0,
                });
                setTimeout(msg, 3000);
            },
            // 导入成功
            wagesImportSuccess(e) {
                this.wages_import_list()
                this.storePramsModal.id = e.data
                this.promptValue = 1
                if(e.code == 200){
                    this.promptModal = true
                    this.title = '审核'
                    this.wages_import_infos()
                }
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
        }
    }
</script>

<style lang="scss" scoped>
.page{
    width: 100%;
    height: 100%;
    background-color: #E9F0F2;
    padding: 20px;
}
.content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
}
.top{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 20px;
}
.Page{
    margin-top: 20px;
    text-align: right;
}
.table-btn{
    width: 48px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #296FE1;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #296FE1;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
.content-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.p-modal{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
}
</style>